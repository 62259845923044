<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe> </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Object
    },
    computed: {
        resources() {
            return this.row.details;
        },
        columns() {
            return [
                {
                    title: this.$t("shiftManagement.keyword"),
                    key: "keyword",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.fromDay"),
                    key: "dayFrom",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.toDay"),
                    key: "dayTo",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.firstIn"),
                    key: "first_in",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.firstOut"),
                    key: "first_out",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.secondIn"),
                    key: "second_in",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.secondOut"),
                    key: "second_out",
                    align: "center"
                },
                {
                    title: this.$t("shiftManagement.totalHous"),
                    key: "total_hour",
                    align: "center"
                }
            ];
        }
    }
};
</script>
